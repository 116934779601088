import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/videoprocessor/LISTEN VP9000/Product-Page.json";
import Datasheet from "../../../../content/produk/controller/videoprocessor/LISTEN VP9000/datasheet-listen-vp9000.pdf";
import ProductImage from "../../../../images/product/controller/videoprocessor/LISTEN VP9000/Product-Summary-Listen-VP-9000.jpg";
const listenvp9000 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>{Content.seo.title}</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p>
                            LS-VP9000 LED Video Processor, has 11 channels for
                            video input, 4 channels for video output, Which can
                            be widely used in engineering, rental performance,
                            irregular/large LED display screen/mixed LED
                            display(different spacing), large stage theater,
                            display and exhibition etc.
                        </p>
                        <ul>
                            <li>
                                5.3 million pixels Show 4 frames simultaneously
                            </li>
                            <li>One key to set Stitching</li>
                            <li>60 HDMI pictures Template/preview operation</li>
                            <li>
                                Chroma key Seamless switch / fade in/ fade out
                            </li>
                            <li>
                                Timing Self-defined input/ output resolution
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

listenvp9000.propTypes = {
    siteTitle: PropTypes.string,
};

listenvp9000.defaultProps = {
    siteTitle: ``,
};
export default listenvp9000;
